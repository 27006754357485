<script lang="ts" setup>
import { computed } from 'vue'
import RoomieTag from '@lahaus-roomie/roomie/src/components/RoomieTag/index.vue'
import type { Props } from './types'
import ImageShowcase from '@/components/ImageShowcase/index.vue'
import { getDeliveryTermLabel, getBedroomsLabel } from '@/utils/project'

import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  blank: true,
  isBrochure: false,
  exactBedrooms: null
})

const { t } = useI18n()

const coverPicture = {
  src: `${props.project.cover}?compress,enhance,format&?&w=500&h=500&ar=1:1`,
  alt: t('coverOf', { name: props.project.name })
}

const route = useRoute()
const router = useRouter()

const goToProject = (url: string) => {
  const targetUrl = props.isBrochure ? `${props.project.path.split('/')[3]}?email=${route.query.email}` : url

  if (props.blank) {
    return navigateTo(targetUrl, {
      open: {
        target: '_blank'
      }
    })
  }

  router.push(targetUrl)
}

const images = computed(() => {
  const filteredPictures = props.project.pictures_v2?.filter(picture => picture.type === 'gallery') || []

  const picturesList = filteredPictures.map(picture => ({
    src: `${picture.url}?compress,enhance,format&?&w=500&h=500&ar=1:1`,
    alt: t('coverOf', { name: props.project.name })
  }))

  return [coverPicture, ...picturesList].slice(0, 5)
})

const getBedroomsNumber = () => {
  if (props.exactBedrooms !== null) return t('project.bedrooms.exact', { n: props.exactBedrooms })
  else return getBedroomsLabel(props.project.typologies || [], t)
}

const tag = computed(() => {
  return `${getDeliveryTermLabel(props.project.delivered_at, t)} · ${getBedroomsNumber()}`
})

const resolveCityLabelName = (cityLabel: string) => {
  return cityLabel.toLowerCase() === t('longCity')
    ? t('shortCity')
    : cityLabel
}
</script>

<script lang="ts">
export default {
  name: 'InvestmentProjectCard'
}
</script>

<template>
  <div
    class="investment-project-card"
    :data-lh-id="`investment-project-card-${site}`"
    @click="goToProject(project.path)">
    <RoomieTag
      :id="`investment-project-card-${project.code}`"
      class="investment-project-card__tag"
      variant="success"
      :label="tag" />

    <ImageShowcase
      class="investment-project-card__background"
      :images="images"
      :data-lh-id="`investment-project-card-${site}`" />

    <div class="investment-project-card__container">
      <div class="investment-project-card__shadow" />

      <div class="investment-project-card__content">
        <p class="text-brand-300 text-20 font-medium truncate">
          {{ project.name }}
        </p>

        <p class="text-18 truncate">
          {{ project.neighborhood_label }}, {{ resolveCityLabelName(project.city_label) }}
        </p>

        <div class="mt-14 flex justify-between text-14">
          <p>
            {{ t('priceFrom') }}
          </p>

          <p>
            {{ project.price_label }} {{ project.currency }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
